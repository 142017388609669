import {
  Card,
  CardBody,
  Spinner,
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow,
} from '@nextui-org/react';
import { IoMdArrowDropright } from 'react-icons/io';
import { MdCheck, MdClose, MdWarning } from 'react-icons/md';
import { useQuery } from 'react-query';
import ApiService from 'services/ApiService';

export default function CrawlerModal({ linkId = null, onClose = null }) {
  const {
    data: logs,
    isLoading,
    isError,
  } = useQuery(
    ['links', linkId, 'logs'],
    async () => {
      // add start and end month if they exist
      const request = `activity-logs/${linkId}`;
      return await ApiService.get(request, {
        params: {
          limit: 100,
          type: 'App\\Models\\DealLink',
          event: 'updated',
        },
      });
    },
    {
      enabled: linkId != null,
    },
  );

  const getIcon = (status) => {
    switch (status) {
      case 'active':
        return <MdCheck className='text-green-600' />;
      case 'failed':
        return <MdClose className='text-red-600' />;
      default:
        return <MdWarning className='text-yellow-600' />;
    }
  };

  const Loading = () => (
    <div className='flex h-full w-full justify-center py-24'>
      <Spinner size='lg' />
    </div>
  );

  const NoData = () => (
    <div className='flex h-full w-full items-center justify-center'>
      <span className='text-lg font-semibold text-default-500'>
        Nothing to show
      </span>
    </div>
  );

  return (
    <div className='min-h-64'>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          {logs?.data?.data?.map((log) => (
            <div key={log.id}>
              <div className='my-4 flex justify-center'>
                <div className='rounded-full bg-default-50 px-2.5 py-0.5 text-xs font-medium text-default-500'>
                  {log.created_at}
                </div>
              </div>
              <div className='mb-2 flex items-center gap-2'>
                <Card className='w-full grow overflow-visible border opacity-60'>
                  <CardBody className='relative overflow-visible'>
                    <div className='absolute -top-3 left-3 z-[999] mb-2 rounded-full bg-danger-50 px-2 text-xs font-semibold text-danger-700'>
                      Old
                    </div>
                    <Table removeWrapper aria-label='Old values'>
                      <TableHeader>
                        <TableColumn>KEY</TableColumn>
                        <TableColumn>VALUE</TableColumn>
                      </TableHeader>
                      <TableBody>
                        {Object.entries(log.properties.old).map(
                          ([key, value], index) => {
                            return key == 'updated_at' ? null : (
                              <TableRow key={index}>
                                <TableCell>{key}</TableCell>
                                <TableCell>{value}</TableCell>
                              </TableRow>
                            );
                          },
                        )}
                      </TableBody>
                    </Table>
                  </CardBody>
                </Card>
                <IoMdArrowDropright className='shrink-0 text-2xl text-default-500' />
                <Card className='w-full grow overflow-visible border'>
                  <CardBody className='relative overflow-visible'>
                    <div className='absolute -top-3 right-3 z-[999] mb-2 rounded-full bg-success-50 px-2 text-xs font-semibold text-success-700'>
                      New
                    </div>
                    <Table removeWrapper aria-label='Old values'>
                      <TableHeader>
                        <TableColumn>KEY</TableColumn>
                        <TableColumn>VALUE</TableColumn>
                      </TableHeader>
                      <TableBody>
                        {Object.entries(log.properties.attributes).map(
                          ([key, value], index) => {
                            return key == 'updated_at' ? null : (
                              <TableRow key={index}>
                                <TableCell>{key}</TableCell>
                                <TableCell>{value}</TableCell>
                              </TableRow>
                            );
                          },
                        )}
                      </TableBody>
                    </Table>
                  </CardBody>
                </Card>
              </div>
            </div>
          ))}
          {logs?.data?.meta?.total == 0 && <NoData />}
        </>
      )}
    </div>
  );
}
