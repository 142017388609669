import { Spinner } from '@nextui-org/react';
import { FaSpinner } from 'react-icons/fa';

export default ({ className }) => {
  return (
    // center fixed spinner
    <div className='fixed left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 transform'>
      <div className='flex aspect-square h-16 items-center justify-center rounded-full bg-white shadow-lg'>
        <Spinner size='lg' />
      </div>
    </div>
  );
};
