import { Fax } from '@mui/icons-material';
import { Checkbox } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import Card from 'components/card';
import Loading from 'components/loading/Loading';
import { useUpdateRolePermissionMutation } from 'mutations';
import { useRoles } from 'queries';
import { useEffect, useState } from 'react';
import { FaCheck } from 'react-icons/fa';
import { IoCheckmark, IoClose } from 'react-icons/io5';
import { MdCheck } from 'react-icons/md';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import ApiService from 'services/ApiService';

const Roles = () => {
  const { data, isLoading, isFetching } = useRoles();

  useEffect(() => {
    if (data) {
      setDynamicColumns();
      setRows(
        data.data.map((role) => {
          return {
            id: role.id,
            name: role.name,
            ...role.permissions,
          };
        }),
      );
    }
  }, [data]);

  const setDynamicColumns = () => {
    let dynamicColumns = [
      {
        field: 'id',
        headerName: 'ID',
        renderCell: (params) => (
          <span className='font-semibold capitalize text-gray-700'>
            {params.row.id}
          </span>
        ),
      },
      {
        field: 'name',
        headerName: 'Role',
        renderCell: (params) => (
          <span className='font-semibold capitalize text-gray-700'>
            {params.row.name}
          </span>
        ),
      },
    ];
    for (const property in data.data[0].permissions) {
      dynamicColumns.push({
        field: property,
        headerName: property,
        align: 'center',
        renderCell: (params) => {
          console.log(params.field);
          return (
            <Checkbox
              checked={params.row[property]}
              checkedIcon={<MdCheck className='text-3xl text-green-500' />}
              icon={<IoClose className='text-3xl text-gray-300' />}
              onChange={(e) => {
                updateRolePermissionMutation.mutate({
                  role_id: params.row.id,
                  permission: params.field,
                });
              }}
            />
          );
        },
      });
    }
    setColumns(dynamicColumns);
  };

  const [rows, setRows] = useState([]);
  const [columns, setColumns] = useState();

  const queryClient = useQueryClient();

  const updateRolePermissionMutation = useUpdateRolePermissionMutation(data, {
    onSuccess: (data) => {
      queryClient.invalidateQueries('roles');
    },
  });

  return (
    <Card extra='rounded-[20px] p-3 h-full min-h-[633.5px] mt-4'>
      <DataGrid
        rows={rows}
        columns={columns || []}
        pageSize={10}
        loading={
          isLoading || isFetching || updateRolePermissionMutation.isLoading
        }
        sx={{
          border: 0,
          borderColor: 'primary.light',
          '&, [class^=MuiDataGrid]': { border: 'none' },
          fontFamily: 'DM Sans',
        }}
        slotProps={{
          loadingOverlay: {
            variant: 'linear-progress',
            noRowsVariant: 'skeleton',
          },
        }}
        hideFooter
        isRowSelectable={false}
      />
    </Card>
  );
};

export default Roles;
