import { Input } from '@material-tailwind/react';
import Modal from 'components/modals/Modal';
import ErrorHelper from 'helpers/ErrorHelper';
import { customSelectStyles, defaultToastOptions } from 'options';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import Select from 'react-select';
import { toast } from 'react-toastify';
import ApiService from 'services/ApiService';
import resolveConfig from 'tailwindcss/resolveConfig';
import tailwindConfig from '../../../../tailwind.config';
import { useStoreActions } from 'easy-peasy';
import { Button } from '@nextui-org/react';
const twConfig = resolveConfig(tailwindConfig);

const HelperText = ({ text }) => {
  return <p className='mt-1 text-xs text-red-500'>{text}</p>;
};

const EditSupplier = ({ modelId = null }) => {
  const [currencies, setCurrencies] = useState([]);
  const [selectedCurrency, setSelectedCurrency] = useState(null);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);
  const closeModal = useStoreActions((actions) => actions.modal.closeModal);

  const {
    data: formData,
    isFetching,
    isError: formDataIsError,
  } = useQuery(
    ['supplier-form', modelId],
    async () => {
      return await ApiService.get(
        `clients/edit${modelId ? '/' + modelId : ''}`,
      );
    },
    {
      staleTime: modelId == null ? 120000 : 0,
      cacheTime: modelId == null ? 122000 : 0,
    },
  );

  useEffect(() => {
    if (!formData) {
      return;
    }
    if (modelId) {
      setValue('name', formData.data.client.name);
      setValue('vat', formData.data.client.vat);
      setValue('discount', formData.data.client.discount);
    }
    setCurrencies(
      formData.data.currencies.map((currency) => {
        return {
          value: currency.id,
          label: currency.name,
        };
      }),
    );

    setPaymentMethods(
      formData.data.payment_methods.map((payment) => {
        return {
          value: payment.id,
          label: payment.name,
        };
      }),
    );
  }, [formData]);

  useEffect(() => {
    if (!formData) {
      return;
    }
    if (!modelId) {
      return;
    }
    const label = currencies.find(
      (currency) => currency.value === formData.data.client.currency_id,
    )?.label;
    if (!label) return;
    setValue('currency', {
      value: formData.data.client.currency_id,
      label,
    });
  }, [currencies]);

  useEffect(() => {
    if (!formData) {
      return;
    }
    if (!modelId) {
      return;
    }
    const label = paymentMethods.find(
      (payment) => payment.value === formData.data.client.payment_id,
    )?.label;
    if (!label) return;
    setValue('payment_method', {
      value: formData.data.client.payment_id,
      label,
    });
  }, [paymentMethods]);

  const {
    register,
    handleSubmit,
    reset,
    control,
    clearErrors,
    getValues,
    setValue,
    formState: { errors, isDirty },
  } = useForm();
  const queryClient = useQueryClient();

  const createSupplierMutation = useMutation(
    async (data) => await ApiService.post('/clients', data),
  );

  const updateSupplierMutation = useMutation(
    async (data) => await ApiService.put('/clients/' + modelId, data),
  );

  const onSubmit = () => {
    if (!modelId) {
      if (!isDirty) {
        return;
      }
    }
    if (Object.keys(errors).length > 0) {
      return;
    }
    const data = {
      name: getValues('name'),
      vat: getValues('vat'),
      currency_id: getValues('currency')['value'],
      payment_method_id: getValues('payment_method')['value'],
    };
    if (getValues('discount')) {
      data.discount = getValues('discount');
    }
    const toastId = toast.loading(
      `${modelId ? 'Updating' : 'Creating'} supplier...`,
    );
    if (modelId) {
      return updateSupplierMutation.mutate(data, {
        onSuccess: () => {
          queryClient.invalidateQueries('clients');
          reset();
          closeModal();
          toast.update(toastId, {
            render: 'Supplier updated successfully',
            type: 'success',
            ...defaultToastOptions,
          });
        },
        onError: (error) => {
          if (error.response && error.response.status === 422) {
            return ErrorHelper.handleValidationErrors(
              error.response.data.errors,
              toastId,
            );
          }
          ErrorHelper.handleApiError(error.response.status, toastId);
        },
      });
    }
    createSupplierMutation.mutate(data, {
      onSuccess: () => {
        queryClient.invalidateQueries('clients');
        reset();
        closeModal();
        toast.update(toastId, {
          render: 'Supplier created successfully',
          type: 'success',
          ...defaultToastOptions,
        });
      },
      onError: (error) => {
        if (error.response && error.response.status === 422) {
          return ErrorHelper.handleValidationErrors(
            error.response.data.errors,
            toastId,
          );
        }
        ErrorHelper.handleApiError(error.response.status, toastId);
      },
    });
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className='mb-[20px] flex flex-col gap-y-6'>
          <div>
            <Input
              className=''
              variant='static'
              placeholder='Enter name'
              label='Name*'
              color={`${errors.name ? 'red' : 'indigo'}`}
              labelProps={{ className: 'text-gray-700' }}
              {...register('name', {
                required: true,
                minLength: 3,
                maxLength: 1000,
              })}
            />
            {errors.name && errors.name.type === 'required' && (
              <HelperText text='Name is required' />
            )}
            {errors.name && errors.name.type === 'minLength' && (
              <HelperText text='Name must be at least 3 characters' />
            )}
          </div>
          <div>
            <Input
              className=''
              variant='static'
              label='VAT*'
              placeholder='Enter VAT'
              color={`${errors.name ? 'red' : 'indigo'}`}
              labelProps={{ className: 'text-gray-700' }}
              {...register('vat', {
                required: true,
                minLength: 3,
                maxLength: 50,
              })}
            />
            {errors.vat && errors.vat.type === 'required' && (
              <HelperText text='VAT is required' />
            )}
            {errors.vat && errors.vat.type === 'minLength' && (
              <HelperText text='VAT must be at least 3 characters' />
            )}
            {errors.vat && errors.vat.type === 'validate' && (
              <HelperText text={errors.vat.message} />
            )}
          </div>
          <div>
            <Input
              className=''
              variant='static'
              label='Discount'
              placeholder='Enter discount percentage'
              color={`${errors.name ? 'red' : 'indigo'}`}
              labelProps={{ className: 'text-gray-700' }}
              {...register('discount', {
                min: 0,
                max: 100,
              })}
            />
            {errors.url && errors.url.type === 'required' && (
              <HelperText text='Url is required' />
            )}
          </div>
          <div>
            <Controller
              name='currency'
              control={control}
              rules={{ required: 'Please select a currency' }}
              value={getValues('currency')}
              render={({ field }) => (
                <Select
                  {...field}
                  options={currencies}
                  isClearable
                  placeholder='Select a currency...'
                  styles={customSelectStyles}
                />
              )}
            />
            {errors.currency && <HelperText text={errors.currency.message} />}
          </div>
          <div>
            <Controller
              name='payment_method'
              control={control}
              rules={{ required: 'Please select a payment method' }}
              render={({ field }) => (
                <Select
                  {...field}
                  options={paymentMethods}
                  isClearable
                  placeholder='Select a payment method...'
                  styles={customSelectStyles}
                />
              )}
            />
            {errors.payment_method && (
              <HelperText text={errors.payment_method.message} />
            )}
          </div>
        </div>
        <div className='flex gap-3 pb-4'>
          <Button
            onPress={(e) => {
              reset();
              closeModal();
            }}
            variant='light'
            fullWidth
            className='text-default-500'
          >
            Cancel
          </Button>
          <Button type='submit' color='primary' fullWidth>
            {createSupplierMutation.isLoading ? 'Saving...' : 'Save'}
          </Button>
        </div>
      </form>
    </>
  );
};
export default EditSupplier;
