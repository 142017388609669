import { Input } from '@material-tailwind/react';
import { Button } from '@nextui-org/react';
import Modal from 'components/modals/Modal';
import { useStoreActions } from 'easy-peasy';
import ErrorHelper from 'helpers/ErrorHelper';
import { defaultToastOptions } from 'options';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import CountryService from 'services/CountryService';

const HelperText = ({ text }) => {
  return <p className='mt-1 text-xs text-red-500'>{text}</p>;
};

const CreateCountry = ({ isOpen, onClose }) => {
  const {
    register,
    handleSubmit,
    watch,
    reset,
    clearErrors,
    getValues,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    if (isOpen) {
      reset();
    }
  }, [isOpen]);

  const queryClient = useQueryClient();

  const closeModal = useStoreActions((actions) => actions.modal.closeModal);

  const createCountryMutation = useMutation(async (data) => {
    return await CountryService.create(data);
  });

  const onSubmit = () => {
    const data = getValues();
    const toastId = toast.loading('Creating country...');
    createCountryMutation.mutate(
      {
        countryname: data.name,
        countrycode: data.countryCode,
        code: data.code,
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries('countries');
          onClose();
          toast.update(toastId, {
            render: 'Country created successfully',
            type: 'success',
            ...defaultToastOptions,
          });
        },
        onError: (error) => {
          if (error.response && error.response.status === 422) {
            return ErrorHelper.handleValidationErrors(
              error.response.data.errors,
              toastId,
            );
          }
          ErrorHelper.handleApiError(error.response.status, toastId);
        },
      },
    );
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className='mb-[20px] flex flex-col gap-y-4'>
          <div>
            <Input
              className=''
              variant='standard'
              label='Name'
              color={`${errors.name ? 'red' : 'indigo'}`}
              labelProps={{ className: 'text-gray-700' }}
              {...register('name', {
                required: true,
                minLength: 3,
                maxLength: 50,
              })}
            />
            {errors.name && errors.name.type === 'required' && (
              <HelperText text='Name is required' />
            )}
            {errors.name && errors.name.type === 'minLength' && (
              <HelperText text='Name must be at least 3 characters' />
            )}
          </div>
          <div>
            <Input
              className=''
              variant='standard'
              label='Country code'
              color={`${errors.code ? 'red' : 'indigo'}`}
              labelProps={{ className: 'text-gray-700' }}
              {...register('countryCode', {
                required: true,
                minLength: 3,
                maxLength: 3,
              })}
            />
            {errors.countryCode && errors.countryCode.type === 'required' && (
              <HelperText text='Country code is required' />
            )}
            {errors.countryCode && errors.countryCode.type === 'minLength' && (
              <HelperText text='Country code must be at least 3 characters' />
            )}
          </div>
          <div>
            <Input
              className=''
              variant='standard'
              label='Code'
              color={`${errors.code ? 'red' : 'indigo'}`}
              labelProps={{ className: 'text-gray-700' }}
              {...register('code', {
                required: true,
                minLength: 2,
                maxLength: 2,
              })}
            />
            {errors.code && errors.code.type === 'required' && (
              <HelperText text='Code is required' />
            )}
            {errors.code && errors.code.type === 'minLength' && (
              <HelperText text='Code must be at least 2 characters' />
            )}
          </div>
          <div className='flex gap-4'>
            <Button
              onPress={closeModal}
              variant='light'
              size='lg'
              fullWidth
              className='text-foreground-500'
            >
              Cancel
            </Button>
            <Button
              variant='solid'
              color='primary'
              size='lg'
              fullWidth
              type='submit'
            >
              Confirm
            </Button>
          </div>
        </div>
      </form>
    </>
  );
};
export default CreateCountry;
