import { useQuery } from 'react-query';
import ApiService from 'services/ApiService';

export const useCategories = (options = {}) => {
  return useQuery({
    queryKey: ['categories'],
    queryFn: () => ApiService.get('/categories'),
    ...options,
  });
};

// get single link
export const useLink = (id, options = {}) => {
  return useQuery({
    queryKey: ['link', id],
    queryFn: () => ApiService.get(`/links/${id}`),
    ...{
      enabled: !!id,
      staleTime: 0,
      cacheTime: 0,
      refetchOnWindowFocus: false,
      keepPreviousData: true,
      ...options,
    },
  });
};

export const useRoles = (options = {}) => {
  return useQuery({
    queryKey: ['roles'],
    queryFn: () => ApiService.get('/roles'),
    ...options,
  });
};

export const useWebsites = (options = {}) => {
  return useQuery({
    queryKey: [
      'websites',
      {
        fields: 'id,name',
        search: '',
      },
    ],
    queryFn: () =>
      ApiService.get(`/websites`, {
        params: {
          'fields[]': 'id,name',
          search: '',
          per_page: 300,
        },
      }),
    ...{
      refetchOnWindowFocus: false,
      keepPreviousData: true,
      ...options,
    },
  });
};

export const useMarkets = (options = {}) => {
  return useQuery({
    queryKey: [
      'countries',
      {
        fields: 'id,countryname',
        search: '',
      },
    ],
    queryFn: () =>
      ApiService.get('countries', {
        params: {
          'fields[]': 'id,countryname',
          search: '',
          per_page: 300,
        },
      }),
    ...{ refetchOnWindowFocus: false, keepPreviousData: true, ...options },
  });
};
