import DataTable from 'components/table/DataTable';
import { hasPermissionTo } from 'helpers/AuthHelper';
import DateTimeHelper from 'helpers/DateTimeHelper';
import { useState } from 'react';
import CreateModal from './components/CreateCountry';
import EditModal from './components/EditModal';
import { useStoreActions } from 'easy-peasy';

const Countries = () => {
  const [showCreateModal, setShowCreateModal] = useState(false);
  const openModal = useStoreActions((actions) => actions.modal.openModal);

  const customCellRenderer = (data, header, modelId) => {
    if (header === 'created at') {
      return <div>{DateTimeHelper.formatDate(data)}</div>;
    }
    return null;
  };

  return (
    <div>
      <div className='mt-3 grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-3 3xl:grid-cols-6'>
        <div className={`col-span-6 transition`}>
          <DataTable
            columnsData={[
              {
                Header: 'id',
                accessor: 'id',
              },
              {
                Header: 'name',
                accessor: 'countryname',
              },
              {
                Header: 'code',
                accessor: 'code',
              },
              {
                Header: 'created at',
                accessor: 'created_at',
              },
              {
                Header: 'actions',
              },
            ]}
            customCellRenderer={customCellRenderer}
            modelName='country'
            request='countries'
            onEditClick={(modelId) => {
              openModal({
                name: 'edit-country',
                props: {
                  modelId: modelId,
                },
              });
            }}
            editable
            deletable
            onCreateButtonClick={() => {
              hasPermissionTo('create countries') &&
                openModal({
                  name: 'create-country',
                  props: {
                    title: 'Create Market',
                  },
                });
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default Countries;
