import { Card, CardBody } from '@nextui-org/react';
import BarChart from 'components/charts/BarChart';
import ChartDropdown from 'components/dropdown/ChartDropdown';
import PriceHelper from 'helpers/PriceHelper';
import { topClientsChartOptions } from 'variables/charts';
const TopClients = ({ data, total }) => {
  let chartOptions = { ...topClientsChartOptions };
  chartOptions.xaxis.categories = data.map((item) => item.name).reverse();
  chartOptions.xaxis.labels.show = true;
  chartOptions.yaxis.labels.formatter = function (val) {
    return PriceHelper.formatNumberAsCurrency(val);
  };

  return (
    <Card>
      <CardBody className='overflow-hidden p-4 pb-0'>
        <div className='flex flex-row items-center justify-between'>
          <div className='ml-1 pt-2'>
            <div className='text-xl font-bold text-navy-700 dark:text-white'>
              Top Clients
            </div>
            <span className='text-sm font-medium text-gray-600'>
              All time • By money spent
            </span>
          </div>
        </div>

        <div className='h-full min-h-[300px] w-full pb-0'>
          <BarChart
            chartData={[
              {
                name: 'Deals',
                data: data.map((item) => item.deal_count).reverse(),
              },
            ]}
            chartOptions={chartOptions}
          />
        </div>
      </CardBody>
    </Card>
  );
};

export default TopClients;
