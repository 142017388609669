import { useMutation } from 'react-query';
import ApiService from 'services/ApiService';

const useCsvExport = () => {
  const exportCsv = async ({ endpoint, params }) => {
    const response = await ApiService.get(
      process.env.REACT_APP_API_URL + endpoint,
      {
        responseType: 'blob',
        params,
      },
    );
    return response.data;
  };

  const { mutate, isLoading } = useMutation({
    mutationFn: exportCsv,
    mutationKey: ['export-csv'],
    onSuccess: (data, variables) => {
      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement('a');
      link.href = url;

      const fileName = variables.endpoint.replace(/^\//, '') + '.csv';
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    },
    onError: (error) => {
      console.error('Error downloading the CSV file', error);
    },
  });

  return { exportCsv: mutate, isLoading };
};

export default useCsvExport;
