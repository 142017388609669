import { useStoreActions } from 'easy-peasy';
import Modal from './Modal';

const BulkImportErrorModal = ({ message = null }) => {
  const closeModal = useStoreActions((actions) => actions.modal.closeModal);

  return (
    <>
      <div className='max-h-[50vh] overflow-y-auto'>
        <div className='mb-[20px] text-sm font-medium text-red-500'>
          {message
            .replace(', ', '')
            .split('\n')
            .map((line, index) => (
              <p className='mt-2' key={index}>
                {line}
              </p>
            ))}
        </div>
      </div>
    </>
  );
};
export default BulkImportErrorModal;
