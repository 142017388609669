// Admin Imports
import MainDashboard from 'views/admin/default';
import PaymentMethods from 'views/admin/payment-methods';
import Profile from 'views/admin/profile';
import Users from 'views/admin/users';

// Auth Imports
import SignIn from 'views/auth/SignIn';

// Icon Imports
import {
  FaCode,
  FaGlobeEurope,
  FaHandshake,
  FaKey,
  FaMoneyBill,
  FaUsers,
} from 'react-icons/fa';
import { HiBriefcase } from 'react-icons/hi';
import { IoMdCube } from 'react-icons/io';
import { IoLink } from 'react-icons/io5';
import {
  MdInsertChart,
  MdLink,
  MdLock,
  MdMonitor,
  MdPeople,
  MdPerson,
  MdSpaceDashboard,
} from 'react-icons/md';
import { RiMoneyEuroCircleFill } from 'react-icons/ri';
import { TiWorld } from 'react-icons/ti';
import Countries from 'views/admin/countries';
import CreateDeal from 'views/admin/create-deal';
import Currencies from 'views/admin/currencies';
import Deals from 'views/admin/deals';
import EditDeal from 'views/admin/edit-deal';
import Links from 'views/admin/links';
import Performance from 'views/admin/performance';
import Suppliers from 'views/admin/suppliers';
import Units from 'views/admin/units';
import Websites from 'views/admin/websites';
import ForgotPassword from 'views/auth/ForgotPassword';
import Roles from 'views/admin/roles';

const routes = [
  {
    name: 'Main Dashboard',
    layout: '/admin',
    path: 'default',
    icon: <MdSpaceDashboard className='h-6 w-6' />,
    component: <MainDashboard />,
    permission: 'view main dashboard',
  },
  {
    name: 'Edit Deal',
    layout: '/admin',
    path: 'deals/:id?/edit/page/:page?',
    icon: <TiWorld className='h-6 w-6' />,
    component: <EditDeal />,
    hideFromSidebar: true,
    permission: 'update deals',
  },
  {
    name: 'Edit Deal',
    layout: '/admin',
    path: 'deals/:id?/edit',
    icon: <TiWorld className='h-6 w-6' />,
    component: <EditDeal />,
    hideFromSidebar: true,
    permission: 'update deals',
  },
  {
    name: 'Create Deal',
    layout: '/admin',
    path: 'deals/create',
    icon: <MdPeople className='h-6 w-6' />,
    component: <CreateDeal />,
    hideFromSidebar: true,
    permission: 'create deals',
  },
  {
    name: 'Deals',
    layout: '/admin',
    path: 'deals/:page?',
    icon: <TiWorld className='h-6 w-6' />,
    component: <Deals />,
    hideFromSidebar: true,
    permission: 'view deals',
  },
  {
    name: 'Deals',
    layout: '/admin',
    path: 'deals',
    icon: <FaHandshake className='h-6 w-6' />,
    component: <Deals />,
    permission: 'view deals',
  },
  {
    name: 'Performance',
    layout: '/admin',
    path: 'performance',
    icon: <MdInsertChart className='h-6 w-6' />,
    component: <Performance />,
    permission: 'view performance',
  },
  {
    name: 'Links',
    layout: '/admin',
    path: 'links/:page?',
    icon: <MdLink className='h-6 w-6' />,
    component: <Links />,
    hideFromSidebar: true,
    permission: 'view links',
  },
  {
    name: 'Links',
    layout: '/admin',
    path: 'links',
    icon: <IoLink className='h-6 w-6' />,
    component: <Links />,
    permission: 'view links',
  },
  {
    name: 'Websites',
    layout: '/admin',
    path: 'websites/:page?',
    icon: <FaCode className='h-6 w-6' />,
    component: <Websites />,
    hideFromSidebar: true,
    permission: 'view websites',
  },
  {
    name: 'Websites',
    layout: '/admin',
    path: 'websites',
    icon: <MdMonitor className='h-6 w-6' />,
    component: <Websites />,
    permission: 'view websites',
  },
  {
    name: 'Markets',
    layout: '/admin',
    path: 'countries/:page?',
    icon: <MdPeople className='h-6 w-6' />,
    component: <Countries />,
    hideFromSidebar: true,
    permission: 'view countries',
  },
  {
    name: 'Markets',
    layout: '/admin',
    path: 'countries',
    icon: <FaGlobeEurope className='h-6 w-6' />,

    component: <Countries />,
    permission: 'view countries',
  },
  {
    name: 'Suppliers',
    layout: '/admin',
    path: 'suppliers/:page?',
    icon: <MdPeople className='h-6 w-6' />,
    component: <Suppliers />,
    hideFromSidebar: true,
    permission: 'view clients',
  },
  {
    name: 'Suppliers',
    layout: '/admin',
    path: 'suppliers',
    icon: <HiBriefcase className='h-6 w-6' />,
    component: <Suppliers />,
    permission: 'view clients',
  },
  {
    name: 'Edit User',
    layout: '/admin',
    path: 'users/:id/edit',
    icon: <MdPeople className='h-6 w-6' />,
    component: <Users />,
    hideFromSidebar: true,
    permission: 'view users',
  },
  {
    name: 'Users',
    layout: '/admin',
    path: 'users/:page?',
    icon: <MdPeople className='h-6 w-6' />,
    component: <Users />,
    hideFromSidebar: true,
    permission: 'view users',
  },
  {
    name: 'Users',
    layout: '/admin',
    path: 'users',
    icon: <FaUsers className='h-6 w-6' />,
    component: <Users />,
    permission: 'view users',
  },
  // {
  //   name: "Links",
  //   layout: "/admin",
  //   path: "links/:page?",
  //   icon: <MdLink className="h-6 w-6" />,
  //   component: <Links />,
  //   hideFromSidebar: true,
  // },
  {
    name: 'Roles',
    layout: '/admin',
    path: 'roles',
    icon: <FaKey className='h-6 w-6' />,
    component: <Roles />,
    permission: 'view roles',
  },
  {
    name: 'Payment Methods',
    layout: '/admin',
    path: 'payment-methods',
    icon: <FaMoneyBill className='h-6 w-6' />,
    component: <PaymentMethods />,
    permission: 'view payment methods',
  },
  {
    name: 'Units',
    layout: '/admin',
    path: 'units',
    icon: <IoMdCube className='h-6 w-6' />,
    component: <Units />,
    permission: 'view units',
  },
  {
    name: 'Currencies',
    layout: '/admin',
    path: 'currencies',
    icon: <RiMoneyEuroCircleFill className='h-6 w-6' />,
    component: <Currencies />,
    permission: 'view currencies',
  },
  {
    name: 'Profile',
    layout: '/admin',
    path: 'profile',
    icon: <MdPerson className='h-6 w-6' />,
    component: <Profile />,
    hideFromSidebar: true,
    permission: 'view deals',
  },
  {
    name: 'Sign In',
    layout: '/auth',
    path: 'sign-in',
    icon: <MdLock className='h-6 w-6' />,
    component: <SignIn />,
    hideFromSidebar: true,
  },
  {
    name: 'Forgot Password',
    layout: '/auth',
    path: 'forgot-password',
    icon: <MdLock className='h-6 w-6' />,
    component: <ForgotPassword />,
    hideFromSidebar: true,
  },
];
export default routes;
