import { Card, CardBody } from '@nextui-org/react';
import PieChart from 'components/charts/PieChart';
import { pieChartOptions } from 'variables/charts';

const PieChartCard = ({ data }) => {
  let chartOptions = pieChartOptions;
  chartOptions.labels = data?.map((item) => item.status);
  return (
    <Card className='h-full'>
      <CardBody>
        <div className='flex flex-row justify-between px-3 pt-2'>
          <div>
            <h4 className='text-lg font-bold text-navy-700 dark:text-white'>
              Deals by status
            </h4>
          </div>
        </div>

        <div className='flex h-full flex-col items-center justify-between'>
          <div className='flex w-full flex-grow items-center justify-center'>
            <div className='h-[220px]'>
              <PieChart
                options={chartOptions}
                series={data.map((item) => item.count)}
              />
            </div>
          </div>
          <div className='flex flex-row gap-3 text-sm text-gray-700'>
            <div className='flex flex-col items-center'>
              <div className='flex flex-row items-center gap-1'>
                <div className='h-2 w-2 rounded-full bg-brand-500'></div>
                <span className='text-xs'>Processed</span>
              </div>
              <span className='text-lg font-medium'>
                {data.find((item) => item.status === 'processed').count}
              </span>
            </div>
            <div className='flex flex-col items-center'>
              <div className='flex flex-row items-center gap-1'>
                <div className='h-2 w-2 rounded-full bg-pink-500'></div>
                <span className='text-xs'>Upcoming</span>
              </div>
              <span className='text-lg font-medium'>
                {data.find((item) => item.status === 'upcoming').count}
              </span>
            </div>
            <div className='flex flex-col items-center'>
              <div className='flex flex-row items-center gap-1'>
                <div className='h-2 w-2 rounded-full bg-lime-500'></div>
                <span className='text-xs'>Indexed</span>
              </div>
              <span className='text-lg font-medium'>
                {data.find((item) => item.status === 'indexed').count}
              </span>
            </div>
          </div>
        </div>
      </CardBody>
    </Card>
  );
};

export default PieChartCard;
