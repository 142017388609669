import { Card, CardBody, Skeleton } from '@nextui-org/react';
import WidgetSkeleton from './components/WidgetSkeleton';

const Widget = ({ icon, title, subtitle = null }) => {
  // if (loading) {
  //   return (
  //     <WidgetSkeleton icon={icon} title={title} subtitle={'Placeholder'} />
  //   );
  // }

  return (
    <Card>
      <CardBody className='h-20 flex-grow flex-row items-center px-4'>
        <div className='flex w-auto flex-row items-center'>
          <div className='rounded-full bg-lightPrimary p-3 dark:bg-navy-700'>
            <span className='flex items-center text-brand-500 dark:text-white'>
              {icon}
            </span>
          </div>
        </div>

        <div className='h-50 ml-3 flex w-full flex-col justify-center gap-1'>
          <div>
            <p className='font-dm font-medium text-gray-600'>{title}</p>
          </div>
          <span className='text-md font-bold text-navy-700 dark:text-white 3xl:text-lg'>
            {subtitle}
          </span>
        </div>
      </CardBody>
    </Card>
  );
};

export default Widget;
