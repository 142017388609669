import * as React from 'react';
import { IoMdRefresh, IoMdTrash } from 'react-icons/io';
import { MdMoreHoriz } from 'react-icons/md';
import {
  Dropdown,
  DropdownTrigger,
  DropdownMenu,
  DropdownSection,
  DropdownItem,
  Button,
} from '@nextui-org/react';
import { IoDocumentText } from 'react-icons/io5';
import useCsvExport from 'hooks/useCsvExport';

export default function CustomMenu({
  filters = {},
  onClearAllFiltersButtonClick = () => {},
  onDeleteSelectedButtonClick = () => {},
  selectedCount = 0,
}) {
  const { exportCsv, isLoading } = useCsvExport();

  const handleExport = () => {
    exportCsv({
      endpoint: '/links/export',
      params: { ...filters },
    });
  };

  return (
    <div>
      <Dropdown backdrop='blur'>
        <DropdownTrigger>
          <Button variant='flat' size='sm' className='min-w-8'>
            <MdMoreHoriz className='text-xl text-gray-700' />
          </Button>
        </DropdownTrigger>
        <DropdownMenu aria-label='Datatable actions' variant='flat'>
          <DropdownItem key='export' onPress={handleExport}>
            <div className='flex items-center gap-1'>
              <IoDocumentText className='mr-2 h-4 w-4' />
              <span>Export to CSV</span>
            </div>
          </DropdownItem>
          <DropdownItem
            key='clear-all-filters'
            onPress={() => {
              onClearAllFiltersButtonClick();
            }}
          >
            <div className='flex items-center gap-1'>
              <IoMdRefresh className='mr-2 h-4 w-4' />
              <span>Clear all filters</span>
            </div>
          </DropdownItem>
          <DropdownItem
            key='clear-all-filters'
            onPress={() => {
              onDeleteSelectedButtonClick();
            }}
            color='danger'
            className='text-danger'
          >
            <div className='flex items-center gap-1'>
              <IoMdTrash className='mr-2 h-4 w-4' />
              Delete {selectedCount} selected
            </div>
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </div>
  );
}
