import { Navigate, Route, Routes } from 'react-router-dom';

import { createTheme, ThemeProvider } from '@mui/material';
import ModalManager from 'ModalManager';
import { useStoreState } from 'easy-peasy';
import { domAnimation, LazyMotion } from 'framer-motion';
import AdminLayout from 'layouts/admin';
import AuthLayout from 'layouts/auth';
import { defaultQueryClientOptions, defaultToastOptions } from 'options';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import resolveConfig from 'tailwindcss/resolveConfig';
import { QueryParamProvider } from 'use-query-params';
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6';
import tailwindConfig from './tailwind.config';
import { NextUIProvider } from '@nextui-org/system';
import { Code, Input } from '@nextui-org/react';

const twConfig = resolveConfig(tailwindConfig);

const queryClient = new QueryClient({
  ...defaultQueryClientOptions,
});
const App = () => {
  const theme = useStoreState((state) => state.theme);
  const materialTheme = createTheme({
    shape: {
      borderRadius: 16,
    },
    typography: {
      fontFamily: 'DM Sans',
      allVariants: {
        color: twConfig.theme.colors.gray[800],
        fontSize: 14,
      },
    },
    components: {
      MuiPaper: {
        styleOverrides: {
          root: {
            boxShadow: '0 0.5rem 1rem rgb(0 0 0 / 5%)',
            border: '1px solid #e5e7eb',
          },
        },
      },
      MuiCard: {
        styleOverrides: {
          root: {
            boxShadow: '0 0.5rem 1rem rgb(0 0 0 / 5%)',
            padding: 20,
          },
        },
      },
      MuiInput: {
        defaultProps: {
          variant: 'filled',
          disableUnderline: true,
          style: {
            borderRadius: 16,
          },
        },
      },
      MuiTextField: {
        defaultProps: {
          variant: 'filled',
          slotProps: {
            input: {
              disableUnderline: true,
              style: {
                borderRadius: 16,
              },
            },
          },
        },
      },
      MuiSelect: {
        defaultProps: {
          variant: 'filled',
          disableUnderline: true,
          style: {
            borderRadius: 16,
            border: 0,
            outline: 0,
          },
        },
      },
      MuiButtonBase: {
        defaultProps: {
          disableRipple: true,
        },
      },
    },
    palette: {
      primary: {
        main: twConfig.theme.colors.indigo[500],
      },
      secondary: {
        main: '#f44336',
      },
      grey: {
        main: twConfig.theme.colors.gray[500],
      },
    },
  });

  return (
    <ThemeProvider theme={materialTheme}>
      <QueryClientProvider client={queryClient}>
        <LazyMotion features={domAnimation}>
          <NextUIProvider>
            <div
              className={`${
                theme === 'dark' ? 'dark' : ''
              } text-sm text-gray-800 dark:text-gray-300`}
            >
              <ToastContainer {...defaultToastOptions} />
              <QueryParamProvider adapter={ReactRouter6Adapter}>
                <Routes>
                  <Route path='auth/*' element={<AuthLayout />} />
                  <Route path='admin/*' element={<AdminLayout />} />
                  <Route path='/' element={<Navigate to='/admin' replace />} />
                  <Route path='*' element={<Navigate to='/404' replace />} />
                </Routes>
              </QueryParamProvider>
              <ModalManager />
            </div>
          </NextUIProvider>
        </LazyMotion>
      </QueryClientProvider>
    </ThemeProvider>
  );
};

export default App;
