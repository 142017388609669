import { Tab, Tabs } from '@nextui-org/react';
import React from 'react';

const currencies = [
  {
    key: 'EUR',
    title: 'EUR',
  },
  {
    key: 'USD',
    title: 'USD',
  },
  {
    key: 'DKK',
    title: 'DKK',
  },
  {
    key: 'GBP',
    title: 'GBP',
  },
  {
    key: 'JPY',
    title: 'JPY',
  },
  {
    key: 'CHF',
    title: 'CHF',
  },
];

export default function CurrencySelector({
  onCurrencyChange = null,
  current = 'EUR',
}) {
  const handleSelectChange = (currency) => {
    // e.preventDefault();
    const selectedCurrency = currency;
    onCurrencyChange(selectedCurrency);
  };
  return (
    <div className='rounded-xl bg-default-100 px-3 py-2'>
      <div className='pb-2 text-xs text-foreground-500'>Currency</div>
      <Tabs
        aria-label='Tabs variants'
        variant={'underlined'}
        color='primary'
        selectedKey={current}
        onSelectionChange={onCurrencyChange}
        fullWidth
        items={currencies}
      >
        {(item) => <Tab key={item.key} title={item.title}></Tab>}
      </Tabs>
    </div>
  );
}
