// ModalManager.js
import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
} from '@nextui-org/react';
import AdvancedFiltersModal from 'components/modals/AdvancedFiltersModal';
import BulkImportErrorModal from 'components/modals/BulkImportErrorModal';
import ConfirmationModal from 'components/modals/ConfirmationModal';
import { useStoreActions, useStoreState } from 'easy-peasy';
import CreateCountry from 'views/admin/countries/components/CreateCountry';
import EditModal from 'views/admin/countries/components/EditModal';
import PreviewCsvData from 'views/admin/create-deal/components/PreviewCsvData';
import LinkEditModal from 'views/admin/edit-deal/components/LinkEditModal';
import CrawlerModal from 'views/admin/links/components/CrawlerModal';
import EditSupplier from 'views/admin/suppliers/components/EditSupplier';
import EditUnit from 'views/admin/units/components/EditUnit';
import EditUser from 'views/admin/users/components/EditUser';
import EditWebsite from 'views/admin/websites/components/EditWebsite';

const ModalManager = ({}) => {
  const {
    name,
    props: { title, buttons, ...props },
  } = useStoreState((state) => state.modal);
  const closeModal = useStoreActions((actions) => actions.modal.closeModal);

  const MODAL_COMPONENTS = {
    'bulk-import-error-modal': BulkImportErrorModal,
    confirmation: ConfirmationModal,
    'advanced-filters': AdvancedFiltersModal,
    'preview-csv': PreviewCsvData,
    'edit-link': LinkEditModal,
    'view-crawls': CrawlerModal,
    'create-country': CreateCountry,
    'edit-country': EditModal,
    'edit-website': EditWebsite,
    'edit-user': EditUser,
    'edit-supplier': EditSupplier,
    'edit-unit': EditUnit,
  };

  const ModalComponent = MODAL_COMPONENTS[name];

  return (
    <Modal
      isOpen={name}
      size={'xl'}
      onClose={closeModal}
      backdrop='blur'
      scrollBehavior='outside'
      placement='center'
      {...props}
    >
      <ModalContent>
        {(onClose) => (
          <>
            {title && (
              <ModalHeader className='flex flex-col gap-1'>{title}</ModalHeader>
            )}
            <ModalBody>
              <ModalComponent {...props} onClose={closeModal} />
            </ModalBody>
            {buttons && <ModalFooter>{buttons}</ModalFooter>}
          </>
        )}
      </ModalContent>
    </Modal>
  );
};

export default ModalManager;
