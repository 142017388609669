import { Input, Option, Select } from '@material-tailwind/react';
import { Button } from '@nextui-org/react';
import Modal from 'components/modals/Modal';
import { useStoreActions } from 'easy-peasy';
import ErrorHelper from 'helpers/ErrorHelper';
import { defaultToastOptions } from 'options';
import { useForm } from 'react-hook-form';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import UserService from 'services/UserService';

const HelperText = ({ text }) => {
  return <p className='mt-1 text-xs text-red-500'>{text}</p>;
};

const EditUser = ({ modelId = null }) => {
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors, isDirty },
  } = useForm();
  const queryClient = useQueryClient();
  const closeModal = useStoreActions((actions) => actions.modal.closeModal);

  const { data, isFetching, isError } = useQuery(
    ['users', modelId],
    async () => {
      return await UserService.get(modelId);
    },
    {
      enabled: modelId != null,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        setValue('name', data.name);
        setValue('email', data.email);
        setValue('role', data.role);
      },
      staleTime: 0,
      cacheTime: 0,
    },
  );

  const updateUserMutation = useMutation(async (data) => {
    return await UserService.update(modelId, data);
  });

  register('role', {
    required: true,
  });

  const onSubmit = () => {
    const data = getValues();
    const toastId = toast.loading('Updating user...');
    updateUserMutation.mutate(
      {
        name: data.name,
        email: data.email,
        role: data.role,
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries('users');
          closeModal();
          toast.update(toastId, {
            render: 'User updated successfully',
            type: 'success',
            ...defaultToastOptions,
          });
        },
        onError: (error) => {
          if (error.response && error.response.status === 422) {
            return ErrorHelper.handleValidationErrors(
              error.response.data.errors,
              toastId,
            );
          }
          ErrorHelper.handleApiError(error.response.status, toastId);
        },
      },
    );
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className='mb-[20px] flex flex-col gap-y-4'>
          <div>
            <Input
              className=''
              variant='standard'
              label='Name'
              color={`${errors.name ? 'red' : 'indigo'}`}
              labelProps={{ className: 'text-gray-700' }}
              {...register('name', {
                required: true,
                minLength: 3,
                maxLength: 50,
              })}
            />
            {errors.name && errors.name.type === 'required' && (
              <HelperText text='Name is required' />
            )}
            {errors.name && errors.name.type === 'minLength' && (
              <HelperText text='Name must be at least 3 characters' />
            )}
          </div>
          <div>
            <Input
              className='focus:border-blue-300'
              variant='standard'
              label='Email'
              color={`${errors.email ? 'red' : 'indigo'}`}
              labelProps={{ className: 'text-gray-700' }}
              {...register('email', {
                required: true,
                pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
              })}
            />
            {errors.email && errors.email.type === 'required' && (
              <HelperText text='Email is required' />
            )}
            {errors.email && errors.email.type === 'pattern' && (
              <HelperText text='Email must be valid' />
            )}
          </div>
          <div>
            <Select
              onChange={(value) => setValue('role', value)}
              color={`${errors.role ? 'red' : 'indigo'}`}
              variant='standard'
              label='Select Role'
              labelProps={{ className: 'text-gray-700' }}
              value={getValues('role')}
            >
              <Option value='director' name='director'>
                Director
              </Option>
              <Option value='link builder' name='link builder'>
                Link builder
              </Option>
              <Option value='viewer' name='viewer'>
                Viewer
              </Option>
            </Select>
            {errors.role && errors.role.type === 'required' && (
              <HelperText text='Role is required' />
            )}
          </div>
        </div>
        <div className='flex gap-4 pb-4'>
          <Button
            onPress={closeModal}
            variant='light'
            size='lg'
            fullWidth
            className='text-foreground-500'
          >
            Cancel
          </Button>
          <Button
            type='submit'
            variant='solid'
            color='primary'
            size='lg'
            fullWidth
          >
            {updateUserMutation.isLoading ? 'Saving...' : 'Save'}
          </Button>
        </div>
      </form>
    </>
  );
};
export default EditUser;
