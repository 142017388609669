import { DevTool } from '@hookform/devtools';
import { Step, StepLabel, Stepper } from '@mui/material';
import {
  Autocomplete,
  AutocompleteItem,
  Button,
  Input,
  Select,
  SelectItem,
} from '@nextui-org/react';
import dayjs from 'dayjs';
import DateTimeHelper from 'helpers/DateTimeHelper';
import ErrorHelper, { getErrorMessage } from 'helpers/ErrorHelper';
import { useCategories, useLink, useMarkets, useWebsites } from 'queries';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import ApiService from 'services/ApiService';
import { useDebounce } from 'use-debounce';
import CurrencySelector from './CurrencySelector';

const HelperText = ({ text }) => {
  return <p className='text-xs text-red-500'>{text}</p>;
};

const LinkEditModal = ({ onClose = null, modelId = null, dealId = null }) => {
  const [websiteQuery, setWebsiteQuery] = useState(null);
  const [websiteQueryValue] = useDebounce(websiteQuery, 400);
  const [marketQuery, setMarketQuery] = useState(null);
  const [marketQueryValue] = useDebounce(marketQuery, 400);
  const [liveMonth, setLiveMonth] = useState(null);
  const [activeStep, setActiveStep] = useState(0);
  const [isLastStep, setIsLastStep] = useState(false);
  const [isFirstStep, setIsFirstStep] = useState(false);
  const [selectedCurrency, setSelectedCurrency] = useState('EUR');

  const queryClient = useQueryClient();
  const createLinkMutation = useMutation((data) => {
    return ApiService.post('links', data);
  });
  const updateLinkMutation = useMutation((data) => {
    return ApiService.put(`links/${modelId}`, data);
  });

  const handleCurrencyChange = (currency) => {
    setSelectedCurrency(currency);
  };

  const handleNext = async () => {
    if (activeStep === 0) {
      // validate inputs
      await trigger([
        'referring_site',
        'target_url',
        'category',
        'website',
        'market',
      ]);
      // check if errors is not equal to {}
      if (Object.keys(errors).length > 0) {
        return;
      }
    }
    !isLastStep && setActiveStep((cur) => cur + 1);
  };
  const handlePrev = () => !isFirstStep && setActiveStep((cur) => cur - 1);

  const onSubmit = () => {
    const toastId = toast.loading(
      modelId ? 'Saving link...' : 'Creating link...',
    );
    if (modelId) {
      return updateLinkMutation.mutate(
        {
          website_id: getValues('website'),
          market_id: getValues('market'),
          referring_site: getValues('referring_site'),
          target_url: getValues('target_url'),
          price: getValues('price'),
          anchor_text: getValues('anchor_text'),
          discount_percentage: getValues('discount_percentage'),
          live_month: getValues('live_month'),
          category_id: getValues('category'),
          currency: selectedCurrency,
        },
        {
          onSuccess: () => {
            reset();
            setActiveStep(0);
            queryClient.invalidateQueries(`deals/${dealId}/links`);
            queryClient.invalidateQueries(`links`);
            onClose();
            toast.update(toastId, {
              render: 'Link updated successfully',
              type: 'success',
              isLoading: false,
              autoClose: 3000,
            });
          },
          onError: (error) => {
            let message = ErrorHelper.handleApiError(
              error.response.status,
              toastId,
            );
            if (error.response && error.response.status === 422) {
              return ErrorHelper.handleValidationErrors(
                error.response.data.errors,
                toastId,
              );
            }
            return toast.update(toastId, {
              render: message,
              type: 'error',
              isLoading: false,
              autoClose: 5000,
            });
          },
        },
      );
    }
    createLinkMutation.mutate(
      {
        website_id: getValues('website'),
        market_id: getValues('market'),
        referring_site: getValues('referring_site'),
        target_url: getValues('target_url'),
        price: getValues('price'),
        anchor_text: getValues('anchor_text'),
        live_month: getValues('live_month'),
        deal_id: dealId,
        category_id: getValues('category'),
        discount_percentage: getValues('discount_percentage'),
        currency: selectedCurrency,
        ...data,
      },
      {
        onSuccess: () => {
          reset();
          setActiveStep(0);
          queryClient.invalidateQueries(`deals/${dealId}/links`);
          queryClient.invalidateQueries(`links`);
          onClose();
          toast.update(toastId, {
            render: 'Link created successfully',
            type: 'success',
            isLoading: false,
            autoClose: 3000,
          });
        },
        onError: (error) => {
          let message = ErrorHelper.handleApiError(
            error.response.status,
            toastId,
          );
          if (error.response && error.response.status === 422) {
            return ErrorHelper.handleValidationErrors(
              error.response.data.errors,
              toastId,
            );
          }
          return toast.update(toastId, {
            render: message,
            type: 'error',
            isLoading: false,
            autoClose: 5000,
          });
        },
      },
    );
  };

  const {
    data: websites,
    isFetching: websitesIsFetching,
    isLoading,
    isError,
  } = useWebsites({
    onSuccess: (data) => {
      setValue('name', data.name);
    },
  });

  const {
    data: markets,
    isFetching: marketsIsFetching,
    isLoading: marketsIsLoading,
    isError: marketsIsError,
  } = useMarkets({
    // enabled: !!marketQueryValue,
    onSuccess: (data) => {
      setValue('name', data.countryname);
    },
  });

  const {
    data,
    isFetching: linkIsFetching,
    isLoading: dataIsLoading,
    isError: dataIsError,
  } = useLink(modelId, {
    onSuccess: ({ data }) => {
      setWebsiteQuery(data.data.website_name);
      setMarketQuery(data.data.market_name);
      setLiveMonth(DateTimeHelper.getYearAndMonth(data.data.live_date));
      setSelectedCurrency(data.data.currency.code);
    },
  });

  const getInitialValues = () => {
    return {
      referring_site: data?.data?.data?.referring_site,
      target_url: data?.data?.data?.target_url,
      price: data?.data?.data?.price,
      discount_percentage: data?.data?.data?.discount_percentage,
      anchor_text: data?.data?.data?.anchor_text,
      category: data?.data?.data?.category_id,
      website: data?.data?.data?.website_id,
      live_month: dayjs(data?.data?.data?.live_date).format('YYYY-MM'),
      category: data?.data?.data?.category_id,
      market: data?.data?.data?.country_id,
    };
  };

  const {
    register,
    handleSubmit,
    reset,
    getValues,
    setValue,
    trigger,
    control,
    formState: { errors, isDirty },
  } = useForm({
    mode: 'onChange',
    delayError: 800,
    shouldUnregister: false,
    defaultValues: {
      referring_site: '',
      live_month: dayjs(Date.now()).format('YYYY-MM'),
    },
    values: getInitialValues(),
  });

  const {
    data: categories,
    isLoading: categoriesIsLoading,
    isFetching: categoriesIsFetching,
  } = useCategories();

  const isFetching =
    websitesIsFetching ||
    marketsIsFetching ||
    linkIsFetching ||
    categoriesIsFetching;

  return (
    <>
      <div>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className={`${isFetching ? 'pointer-events-none opacity-50' : ''}`}
        >
          <Stepper activeStep={activeStep} className='mb-6 w-full'>
            <Step key={0}>
              <StepLabel>
                <span className='text-foreground-500'>Enter main info</span>
              </StepLabel>
            </Step>
            <Step key={1}>
              <StepLabel>
                <span className='text-foreground-500'>
                  Enter additional info
                </span>
              </StepLabel>
            </Step>
          </Stepper>
          <div className='mb-[20px] grid grid-cols-1 gap-4'>
            {activeStep === 0 && (
              <>
                <div>
                  <Controller
                    control={control}
                    name='referring_site'
                    rules={{
                      required: {
                        value: true,
                        message: 'Referring site is required',
                      },
                      minLength: {
                        message: 'Referring site must be at least 6 characters',
                        value: 3,
                      },
                    }}
                    render={({ field }) => (
                      <Input
                        label='Referring site'
                        placeholder='Enter the referring site'
                        errorMessage={getErrorMessage(errors, 'referring_site')}
                        isInvalid={!!errors.referring_site}
                        {...field}
                      />
                    )}
                  />
                </div>
                <div>
                  <Controller
                    control={control}
                    name='target_url'
                    rules={{
                      required: {
                        message: 'Target URL is required',
                        value:
                          categories?.data?.data[getValues('category')]
                            ?.title == 'Brand Mention',
                      },
                      minLength: {
                        message: 'Target URL must be at least 3 characters',
                        value: 3,
                      },
                    }}
                    render={({ field }) => (
                      <Input
                        label='Target URL'
                        placeholder='Enter the target URL'
                        errorMessage={getErrorMessage(errors, 'target_url')}
                        isInvalid={!!errors.target_url}
                        {...field}
                      />
                    )}
                  />
                </div>
                <div className='relative'>
                  <Controller
                    control={control}
                    name='website'
                    rules={{
                      required: {
                        value:
                          categories?.data?.data[getValues('category')]
                            ?.title == 'Brand Mention',
                        message: 'Website is required',
                      },
                    }}
                    render={({ field }) => (
                      <Autocomplete
                        defaultItems={
                          websites?.data?.data.map((website) => {
                            return {
                              key: website.id,
                              label: website.name,
                            };
                          }) || []
                        }
                        isVirtualized
                        defaultSelectedKey={`${field.value}`}
                        selectedKey={`${field.value}`}
                        label='Website'
                        placeholder='Select the website'
                        onSelectionChange={(value) => {
                          field.onChange(value);
                        }}
                        errorMessage={getErrorMessage(errors, 'website')}
                        isInvalid={!!errors.website}
                        {...field}
                      >
                        {(item) => (
                          <AutocompleteItem key={item.key}>
                            {item.label}
                          </AutocompleteItem>
                        )}
                      </Autocomplete>
                    )}
                  />
                </div>
                <div className='relative'>
                  <Controller
                    control={control}
                    name='market'
                    rules={{
                      required: {
                        value:
                          categories?.data?.data[getValues('category')]
                            ?.title == 'Brand Mention',
                        message: 'Market is required',
                      },
                    }}
                    render={({ field }) => (
                      <Autocomplete
                        defaultItems={
                          markets?.data?.data.map((market) => {
                            return {
                              key: market.id,
                              label: market.countryname,
                            };
                          }) || []
                        }
                        isVirtualized
                        defaultSelectedKey={`${field.value}`}
                        selectedKey={`${field.value}`}
                        label='Market'
                        placeholder='Select the market'
                        onSelectionChange={(value) => {
                          field.onChange(value);
                        }}
                        errorMessage={getErrorMessage(errors, 'market')}
                        isInvalid={!!errors.market}
                        {...field}
                      >
                        {(item) => (
                          <AutocompleteItem key={item.key}>
                            {item.label}
                          </AutocompleteItem>
                        )}
                      </Autocomplete>
                    )}
                  />
                </div>
                <div>
                  <Controller
                    control={control}
                    name='category'
                    rules={{
                      required: {
                        value: true,
                        message: 'Category is required',
                      },
                    }}
                    render={({ field }) => (
                      <Select
                        label='Category'
                        placeholder='Select a category'
                        className='w-full'
                        isInvalid={!!errors.category}
                        isLoading={categoriesIsLoading}
                        errorMessage={getErrorMessage(errors, 'category')}
                        defaultSelectedKeys={[`${field.value}`]}
                        selectedKeys={[`${field.value}`]}
                        onSelectionChange={field.onChange}
                        {...field}
                      >
                        {categories?.data?.data?.map((category) => (
                          <SelectItem key={category.id}>
                            {category.title}
                          </SelectItem>
                        ))}
                      </Select>
                    )}
                  />
                </div>
              </>
            )}
            {activeStep == 1 && (
              <>
                <div>
                  <Input
                    className=''
                    variant='standard'
                    label='Price'
                    type='number'
                    max={10}
                    placeholder='Enter the price'
                    isInvalid={!!errors.price}
                    errorMessage={getErrorMessage(errors, 'price')}
                    {...register('price', {
                      required: {
                        message: 'Price is required',
                        value: true,
                      },
                      min: {
                        message: 'Price must be greater than 0',
                        value: 0,
                      },
                    })}
                  />
                </div>
                <div>
                  <Input
                    className=''
                    variant='standard'
                    label='Discount percentage'
                    type='number'
                    placeholder='Enter the discount percentage'
                    isInvalid={!!errors.discount_percentage}
                    errorMessage={getErrorMessage(
                      errors,
                      'discount_percentage',
                    )}
                    {...register('discount_percentage', {
                      min: 0,
                      max: 100,
                    })}
                  />
                </div>
                <div>
                  <Input
                    className=''
                    variant='standard'
                    label='Anchor text'
                    placeholder='Enter the anchor text'
                    isInvalid={!!errors.anchor_text}
                    errorMessage={getErrorMessage(errors, 'anchor_text')}
                    {...register('anchor_text', {
                      required: false,
                      maxLength: 500,
                    })}
                  />
                  {errors.name && errors.name.type === 'required' && (
                    <HelperText text='Name is required' />
                  )}
                  {errors.name && errors.name.type === 'minLength' && (
                    <HelperText text='Name must be at least 3 characters' />
                  )}
                </div>
                <div className='rounded-xl bg-default-100 px-3 py-2'>
                  <div className='mb-2 text-xs text-default-500'>
                    Live month
                  </div>
                  <Controller
                    control={control}
                    name='live_month'
                    rules={{ required: true }}
                    render={({ field }) => (
                      <input
                        className='w-full rounded-lg border bg-default-50 px-3 py-2 text-sm outline-0'
                        type='month'
                        id='start'
                        name='start'
                        min='2020-01'
                        // onChange={(e) => setLiveMonth(e.target.value)}
                        // value={liveMonth}
                        isInvalid={!!errors.live_month}
                        {...field}
                      />
                    )}
                  />
                  {errors.live_month &&
                    errors.live_month.type === 'required' && (
                      <HelperText text='Live month is required' />
                    )}
                </div>
                <div>
                  <CurrencySelector
                    onCurrencyChange={handleCurrencyChange}
                    current={selectedCurrency}
                  />
                </div>
              </>
            )}
            <div className='flex justify-end gap-2'>
              <Button
                onPress={(e) => {
                  if (activeStep > 0) {
                    return handlePrev();
                  }
                  onClose();
                }}
                variant='light'
                size='lg'
                fullWidth
                className='text-foreground-500'
              >
                {activeStep == 0 ? 'Cancel' : 'Back'}
              </Button>
              <Button
                onPress={(e) => {
                  if (activeStep == 0) {
                    return handleNext();
                  }
                  handleSubmit(onSubmit)();
                }}
                variant='solid'
                color='primary'
                size='lg'
                fullWidth
              >
                {activeStep == 1 ? (modelId ? 'Save' : 'Create') : 'Next'}
              </Button>
            </div>
          </div>
        </form>
        <DevTool control={control} />
      </div>
    </>
  );
};
export default LinkEditModal;
