import { Button } from '@nextui-org/react';
import { useStoreActions } from 'easy-peasy';

const ConfirmationModal = ({
  title = 'Confirm deletion',
  onConfirm = () => {},
}) => {
  const closeModal = useStoreActions((actions) => actions.modal.closeModal);
  return (
    <div className='flex flex-col gap-4'>
      <span>Are you sure you want to proceed?</span>
      <div className='flex gap-4 pb-4'>
        <Button
          onPress={closeModal}
          variant='light'
          size='lg'
          fullWidth
          className='text-foreground-500'
        >
          Cancel
        </Button>
        <Button
          variant='solid'
          color='primary'
          size='lg'
          fullWidth
          onPress={onConfirm}
        >
          Confirm
        </Button>
      </div>
    </div>
  );
};
export default ConfirmationModal;
