import { Input, Spinner } from '@nextui-org/react';
import React, { useEffect } from 'react';
import { FaSpinner } from 'react-icons/fa';
import { FiSearch } from 'react-icons/fi';
import { useDebouncedCallback } from 'use-debounce';

const SearchInput = ({ value, onChange, loading = false, ...props }) => {
  return (
    <Input
      placeholder='Search...'
      value={value}
      onChange={onChange}
      className='max-w-xs rounded-full'
      startContent={
        loading ? (
          <Spinner size='sm' />
        ) : (
          <FiSearch className='h-5 w-5 text-gray-500' />
        )
      }
      type='text'
      {...props}
    />
  );
};

export default SearchInput;
