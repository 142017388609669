import Loading from 'components/loading/Loading';
import DataTable from 'components/table/DataTable';
import { useStoreActions } from 'easy-peasy';
import DateTimeHelper from 'helpers/DateTimeHelper';
import { useEffect, useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import SupplierService from 'services/SupplierService';

const Suppliers = () => {
  const { page = 1 } = useParams();
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState('');
  const [showModal, setShowModal] = useState(false);
  const openModal = useStoreActions((actions) => actions.modal.openModal);

  const {
    data: suppliers,
    isLoading,
    isError,
    isFetching,
  } = useQuery(
    ['suppliers', page, searchQuery],
    async () => await SupplierService.getAll(page, searchQuery),
    {
      refetchOnWindowFocus: false,
      keepPreviousData: true,
    },
  );

  const queryClient = useQueryClient();

  useEffect(() => {
    queryClient.invalidateQueries('suppliers');
  }, [searchQuery]);

  if (isLoading) {
    return <Loading className='mt-4' />;
  }
  if (isError) {
    return <div>Error</div>;
  }

  const customCellRenderer = (data, header, modelId) => {
    if (header === 'updated at') {
      return <div>{DateTimeHelper.formatDate(data)}</div>;
    }
    if (header === 'discount') {
      return <div>{data && data + '%'}</div>;
    }
    return null;
  };

  const onPageChange = (pageNumber) => {
    navigate('/admin/suppliers/' + (pageNumber.selected + 1));
  };

  const onTableSearch = (query) => {
    navigate('/admin/suppliers');
    setSearchQuery(query);
  };

  return (
    <div>
      <div className='mt-3 grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-3 3xl:grid-cols-6'>
        <div className={`col-span-6`}>
          <DataTable
            columnsData={[
              {
                Header: 'id',
                accessor: 'id',
              },
              {
                Header: 'name',
                accessor: 'name',
              },
              {
                Header: 'discount',
                accessor: 'discount',
              },
              {
                Header: 'updated at',
                accessor: 'updated_at',
              },
              {
                Header: 'actions',
              },
            ]}
            customCellRenderer={customCellRenderer}
            modelName='supplier'
            request='clients'
            editable
            deletable
            onCreateButtonClick={() =>
              openModal({
                name: 'edit-supplier',
                props: {
                  title: 'Create Supplier',
                  modelId: null,
                },
              })
            }
            onEditClick={(modelId) => {
              openModal({
                name: 'edit-supplier',
                props: {
                  title: 'Edit Supplier',
                  modelId,
                },
              });
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default Suppliers;
